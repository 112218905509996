/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    position: relative;
}

/* bootstrap override. start*/

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 0px;
    margin-bottom: 10px;
}

/* bootstrap override. end*/

.view-container {
    width: 100%;
    padding: 15px;
    height: 100%;
    overflow: auto;
}

.custom-dialog-container .mat-dialog-container {
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
}

mat-form-field {
    margin-right: 5px;
}

button+button {
    margin-left: 5px;
}

/* Custom Scrollbar */

/* width */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #444;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-button {
  width:10px;
  height:10px;
  background:#444;
}

/* ng-select section */

.ng-dropdown-panel {
    font-size: 10px;
    width: auto !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0.4375em;
  margin-bottom: 0.1em;
  background-color: #3f51b5;
  border-radius: 2px;
  color: #fff;
  padding: 0px 5px;
}
.ng-select {
  margin-right: 5px;
}

.ng-select-container {
    margin-right: 5px;
    min-height: auto !important; /* matInputとformのサイズを合わせるため */

}

.ng-value-container {
    padding: 3px !important; /* matInputとformのサイズを合わせるため */
}

/* material */
.season-select-panel {
    max-width: none !important;
}

.mat-select-panel {
    max-width: none !important;
}

/* snack-bar */
.web-tagging-page.error-snackbar {
    width: 500px;
    height: 70px;
    padding: 10px 24px;
    margin: 0px !important;
}

.web-tagging-page .error-messages {
    height: 100%;
    overflow: auto;
}

.web-tagging-page .snack-close-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 9999;
}